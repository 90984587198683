import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalsoftTrimDirective } from './calsoft-trim.directive';
import { LazyImgDirective } from './lazy-img.directive';
import { NumberDirective } from './number.directive';
import { NoPrintDirective } from './noprint.directive';



@NgModule({
  declarations: [CalsoftTrimDirective, LazyImgDirective, NumberDirective, NoPrintDirective],
  imports: [
    CommonModule
  ],
  exports: [CalsoftTrimDirective,LazyImgDirective,NumberDirective, NoPrintDirective]
})
export class DirectiveModule { }
