import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() type: string;
  @Input() component: string="dark";
  public serverPath = environment.commonImageApi + "logo/";
  @Input() sitename: string;
  data :any;
  layout: any;
  defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  websiteLogoSize: any;
  constructor( private calsoftService: CalsoftService, private configService: ConfigServiceService,
    private route:Router
    ) { }
  ngOnInit() {

    

  }

  closeMenu(){

    if(this.component=="menu"){
      this.calsoftService.sidenavOpen = false;
    }else{

      if(this.route.url!='/home'){
        this.route.navigate(['/']);
      }

    }

  }
  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.websiteLogoSize=data['websiteLogoSize'];
         
        }, error => {

        }
      );
  }

}
