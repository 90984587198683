<footer [style.backgroundImage]="'url(' + serverPath1+footerBrandImg + ')'" class="footer-v1" style="max-width: 100%;">
  <div *ngIf="this.url=='/home'">
    <div *ngIf="footerImageEnable==1">
      <div *ngIf="imageLoaded">
        <div style="margin-top:auto;">
          <img [src]="serverPath+footerLogoSrc" (error)="onImageError()" style="width: 100%;">
        </div>
      </div>
    </div>
  </div>

  <div class="theme-container">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start" class="py-3 border-bottom-mute">
      <div fxFlex="5"></div>
      <div fxFlex="20">
        <h3 class="col-title">Discover</h3>

        <p class="mt-2"><a routerLink="/aboutus">About Us</a></p>
        <p *ngIf="data.showContactPage==1" class="mt-1"><a routerLink="/contact-us">Contact
            Us</a></p>
        <p style="cursor: pointer;" class="mt-2"><a (click)="gotoLink('/pages/News%20and%20Blogs')">News & Blog</a></p>
        <p style="cursor: pointer;" class="mt-2"><a (click)="gotoLink('/pages/our%20story')">Our Story</a></p>
        <p *ngIf="data.disableCoupoun==1" class="mt-2"><a routerLink="/coupon">Coupon</a></p>
        <p *ngIf="data.showBrochurePage==1" class="mt-2"><a routerLink="/brochure">Brochure</a></p>
        <p style="cursor: pointer;" class="mt-2" *ngIf="data.enableCustomOption==1"><a
            (click)="gotoLink(data.customOptionNavigationLink)">{{data.customOptionFooter}}</a></p>

        <h3 class="col-title" style="margin-top: 20px;margin-bottom: 20px;">Policy</h3>
        <p *ngIf="data.disableRefundPolicy==0"><a routerLink="/returnPolicy"
            style="margin-top: 10px;">{{data.refundPolicyName}}</a></p>
        <p class="mt-2"><a routerLink="/terms&conditions">Terms & Conditions</a></p>
        <p class="mt-2"><a routerLink="/privacy">Privacy Policy</a></p>
        <p *ngIf="data.disableShippingPolicy==0" class="mt-2"><a routerLink="/shippingPolicy">Shipping Policy</a></p>
        <p *ngIf="data.disablePaymentPolicy==0" class="mt-2"><a routerLink="/paymentPolicy">Payment Policy</a></p>
      </div>

      <div fxFlex="20" ngClass.xs="mt-2">
        <h3 class="col-title">Quick Links</h3>

        <p class="mt-2"><a routerLink="/pages/Our%20Partners">Our Partners</a></p>
        <p class="mt-2"><a routerLink="/pages/Subscription">Subscription</a></p>
        <p class="mt-2"><a routerLink="/pages/Roohi%20Rewards">Roohi Rewards</a></p>
        <p class="mt-2"><a routerLink="/pages/Our%20Brands">Our Brands</a></p>
        <p class="mt-2"><a routerLink="/pages/Our%20Platform">Our Platform</a></p>
        <p class="mt-2"><a routerLink="/pages/Our%20Solutions">Our Solutions</a></p>
      </div>

      <div fxFlex="20" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information">
        <h3 class="col-title">Contact Us</h3>

        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
          <a (click)="callPhone()" style="cursor: pointer; display: flex; align-items: center;">
            <mat-icon>call</mat-icon>
            <span class="footer-color" style="margin-left: 8px;">{{ data.storePhoneNumber }}</span>
          </a>
        </p>

        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
          <mat-icon>mail_outline</mat-icon>
          <span style="cursor: pointer;"
            (click)="composeEmail(data.smtpUsername)">&nbsp;&nbsp;{{data.smtpUsername}}</span>
        </p>
        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
          <mat-icon style="padding-right: 20px;">location_on</mat-icon>
          <span *ngIf="data.googleMapEnable==1">
            <span style="cursor: pointer;"
              (click)="getDirections(data.googleMapNavigation)">{{data.streetAddress}}</span>
          </span>
          <span *ngIf="data.googleMapEnable==0">{{data.streetAddress}}</span>
        </p>

      </div>
      <div fxFlex="5"></div>
      <div fxFlex="20" ngClass.xs="mt-2">
        <div *ngIf="showFooterIcon==1">
          <h3 class="col-title">Social Media</h3>
          <div>
            <span fxFlex="15" *ngFor="let item of footerIconData; let i = index">

              <button (click)="navigate(item)" mat-icon-button>

                <mat-icon [svgIcon]="item.iconName" style="color: white;"> </mat-icon>
              </button>
            </span>

          </div><br>
        </div>
        <div>
          <h3 class="col-title">{{data.subscribeText}}</h3>
          <calsoft-subscriber-button2></calsoft-subscriber-button2>

        </div>
        <div *ngIf="data.vendorEnabled == 1" class="padding-30" fxLayoutAlign="start center">
          <a class="border padding-10"  href="https://hg.myhotelline.com/indexWebotel.html">Partner Login</a>
        </div>
      </div>
      <div fxFlex="5"></div>


    </div>
  </div>
</footer>


<div  class="footer-v1"  style="text-align: center;padding-top: 5px;padding-bottom: 5px;font-size: 16px;">
  <span> © {{currentYear}} {{data.storeName}}, All rights reserved.</span>
</div>