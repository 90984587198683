import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  showPopup = false;
  hasReadPolicy = false;
  isChecked = false;
  @Input() privacywords : any= ''

  openPrivacyPolicy(event: Event): void {
    event.preventDefault();
    this.showPopup = true;
  }

  closePrivacyPolicy(): void {
    this.showPopup = false;
  }

  acceptPolicy(): void {
    this.hasReadPolicy = true;
    this.showPopup = false;
  }
}
