<div *ngIf="!locationSelected" style="height: 100%;">
    <div *ngIf="!locationSelected" [formGroup]="firstFormGroup" fxLayout="row" class="search backg"
        fxLayoutAlign="space-between center">
        <div class="padding-10"  fxLayout="row" fxLayoutGap="5px" fxFlex="100s">
          

            <mat-form-field floatLabel='never' fxFlex>
                <input matInput value="search " placeholder="Enter area street name ...." formControlName="search"
                    (input)="onSearchChange($event)" (click)="onSearch()">
                    <mat-icon style=" cursor: pointer;"  *ngIf="firstFormGroup.value.search==''" matSuffix>search</mat-icon>
                    <mat-icon  (click)="removeText()" *ngIf="firstFormGroup.value.search!=''" matSuffix style=" cursor: pointer;">close</mat-icon>
                </mat-form-field>
                <button  *ngIf="showCloseButton" mat-icon-button (click)="closePopup()">
                    <mat-icon style=" cursor: pointer;">
                        close
                    </mat-icon>
                </button>
        </div>

        <!-- <button *ngIf="firstFormGroup.value.search==''" mat-icon-button 
       >
            <mat-icon>
                search
            </mat-icon>
        </button> -->
        <!-- <button *ngIf="firstFormGroup.value.search!=''" 
        mat-icon-button (click)="removeText()">
            <mat-icon>
                close
            </mat-icon>
        </button> -->
    </div>

    <ng-container *ngIf="firstFormGroup.value.search!=''">
        <div class="backg borderb padding-10" fxLayoutAlign="start center" fxLayoutGap="10px"
            *ngFor="let place of places" (click)="userPickLocation(place)">
            <i class="material-icons" style=" cursor: pointer;">
                location_on
            </i>
            <span style=" cursor: pointer;"> {{place.address}}</span>

        </div>
    </ng-container>


    <div *ngIf="firstFormGroup.value.search==''" class="backg borderb padding-10" fxLayoutAlign="start center"
        fxLayoutGap="10px" (click)="userGetLocation()">
        <i class="material-icons" style=" cursor: pointer;">near_me</i>
        <span style=" cursor: pointer;">
            Use my current location
        </span>
    </div>
</div>

<div *ngIf="locationSelected" style="height: 100%;">

    <calsoft-map-location fxFlex="100" [commonData]="commonData"
     [buttonTitle]="buttonTitle"
    (goBackToLocation)="setLocationSelected($event)"
        (gpsAddressData)="addressdata($event)" 
        [address]="separatedAddress"
         [type]="type"
        [title]="title"></calsoft-map-location>

</div>

<div *ngIf="showrecent" style="background-color: white;padding-top: 15px;padding-bottom: 15px;">
    <h6 class="tex1"><b>RECENT SEARCHES</b></h6>
    
    <div fxLayout="row" *ngFor="let search of recentSearches" >
        <div fxFlex="12" fxLayoutAlign="center center">
            <mat-icon style="font-size: 22px;">query_builder</mat-icon>
        </div>
        <div fxFlex="88" style="padding-bottom: 10px;" (click)="userRecentLocation(search)">
            <p class="searchttext"><b>{{search.title}}</b></p>
            <p class="truncate-text">{{search.FullAddress}}</p>
        </div>
    </div>
</div>
    