import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { InputComponent } from "../input/input.component";
import { ButtonComponent } from "../button/button.component";
import { SelectComponent } from "../select/select.component";
import { DateComponent } from "../date/date.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { FieldConfig } from "../field.interface";
import { PriceComponent } from "../price/price.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { PasswordConfirmComponent } from "../password-confirm/password-confirm.component";
import { PasswordComponent } from "../password/password.component";
import { ImageComponent } from "../image/image.component";
import { PhoneNumberComponent } from "src/app/pages/phone-number/phone-number.component";
import { PhoneNoComponent } from "../phone-no/phone-no.component";

const componentMapper = {
  text: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  price:PriceComponent,
  file:FileUploadComponent,
  password:PasswordComponent,
  passwordConfirm:PasswordConfirmComponent,
  image:ImageComponent,
  countrycode:PhoneNoComponent
};
@Directive({
  selector: "[dynamicField]"
})
export class DynamicFieldDirective implements OnInit, OnDestroy {
  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  @Input() index: any;
  @Input() indexInital: any;
  componentRef: any;

  @Output() valueChange = new EventEmitter();
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}
  ngOnInit() {
    
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.inputType]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.index = this.index;
    this.componentRef.instance.indexInital = this.indexInital;

    if (this.componentRef.instance.valueChange) {
      this.componentRef.instance.valueChange.subscribe((value: any) => {

        console.log(value);

        this.componentRef.instance.indexInital = value;
        
        this.valueChange.emit(value);
      });
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy(); // Clean up component on directive destroy
    }
  }
}
