<div *ngIf="display">

    <div *ngIf="url!='/home'">
        <mat-toolbar style="height: 120px; z-index: 1000;" fxHide fxShow.gt-md class="  bg"
        fxLayoutAlign="space-between center">

        <mat-toolbar-row class="theme-container adj" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="20">


                <section>
    
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
    
                </section>
    
            </div>
    
            <!-- <div fxFlex fxHide fxShow.gt-sm class="list-flex user-tool"> -->
            <div fxLayout="row" fxLayoutAlign="end center"
                style="border-radius: 60px;box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);padding: 12px ;z-index: 1000;
                margin-top: 10px;">
   
                <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'"
                    [categoryArray]="item"></calsoft-Menu>
            
    
                <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="data.disableLoginHeader==0">
                    <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                        <button class="btncss" *ngIf="!userLoggedIn">Login/Signup
                        </button>
                    </div>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
    
                <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
                    *ngIf="userLoggedIn" class="dropcss"></calsoft-HeaderUserProfileDropdown>
                </div>
    
                <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="url != '/cart'">
                    <calsoft-cart-icon [data]="data"  ></calsoft-cart-icon>
                </div>
    
            </div>
        </mat-toolbar-row>
        </mat-toolbar>

    </div>

    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="logo">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </div>
            <div fxLayout="row">
                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                    fxLayoutAlign="space-between center">
                 
                    <div fxLayout="row" fxLayoutAlign="end center"
                    style="border-radius: 60px;background: white;  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);padding: 12px ;z-index: 1000;
                    margin-top: 10px;">
        
                    <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'"
                        [categoryArray]="item"></calsoft-Menu>
                
        
                    <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="data.disableLoginHeader==0">
                        <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                            <button class="btncss" *ngIf="!userLoggedIn">Login/Signup
                            </button>
                        </div>
                    </div>
                    <div *ngIf="data.disableLoginHeader==0">
        
                    <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
                        *ngIf="userLoggedIn" class="dropcss"></calsoft-HeaderUserProfileDropdown>
                    </div>
        
                    <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="url != '/cart'">
                        <calsoft-cart-icon [data]="data"  ></calsoft-cart-icon>
                    </div>
        
                </div>
                </mat-toolbar-row>

                <!-- <calsoft-cart-icon [data]="data"  fxFlex></calsoft-cart-icon> -->
            </div>

        </div>
    </div>

    <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
    fxLayoutAlign="space-between center" style="height: 120px;z-index: 1000;">
    <mat-toolbar-row class="theme-container adj" fxLayoutAlign="space-between center">

        <div fxLayout="row" fxLayoutGap="10px" fxFlex="20">


            <section>

                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>

        </div>

        <!-- <div fxFlex fxHide fxShow.gt-sm class="list-flex user-tool"> -->
        <div fxLayout="row" fxLayoutAlign="end center"
            style="border-radius: 60px;box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);padding: 12px ;z-index: 1000;
            margin-top: 10px;">

            <calsoft-Menu fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'one'"
                [categoryArray]="item"></calsoft-Menu>
        

            <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="data.disableLoginHeader==0">
                <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                    <button class="btncss" *ngIf="!userLoggedIn">Login/Signup
                    </button>
                </div>
            </div>
            <div *ngIf="data.disableLoginHeader==0">

            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
                *ngIf="userLoggedIn" class="dropcss"></calsoft-HeaderUserProfileDropdown>
            </div>

            <div style="z-index: 1000;" fxLayoutAlign="end center" *ngIf="url != '/cart'">
                <calsoft-cart-icon [data]="data"  ></calsoft-cart-icon>
            </div>

        </div>
    </mat-toolbar-row>
</mat-toolbar>



</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>