import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient) { }

  public url = "assets/data/";
  url2 = environment.serverAPI4 + "/api/technician"; 

  getTrackingInfo(): Observable<any> {
    return this.http.get<any>(this.url + 'tracking.json');
  }
  getShipRocket() {
    return this.http.get<any>(this.url + 'ship-rocket.json');

  }

  getTechnicianId(): Observable<Object> {
    return this.http.get(`${this.url2}/get`);
  }
 
  trackLocation(orderId:any): Observable<Response[]> {
    return this.http.get<Response[]>(`${this.url2}/${orderId}`);
  }

}
