import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNoPrint]'
})
export class NoPrintDirective {
  @Input() noCopy: boolean = false;
  @Input() noPrint: boolean = false;
  @Input() noScreenshot: boolean = false;
  @Input() autoBlur: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, '-webkit-touch-callout', 'none');
    this.renderer.setStyle(this.el.nativeElement, '-webkit-user-select', 'none');
    this.renderer.setStyle(this.el.nativeElement, '-moz-user-select', 'none');
    this.renderer.setStyle(this.el.nativeElement, '-ms-user-select', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'user-select', 'none');
  }

  @HostListener('copy', ['$event'])
  onCopy(event: ClipboardEvent) {
    if (this.noCopy) {
      event.preventDefault();
    }
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
    if (this.noCopy) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.noPrint && (event.ctrlKey && event.key === 'p')) {
      event.preventDefault();
    }
    if (this.noScreenshot && (event.key === 'PrintScreen')) {
      navigator.clipboard.writeText('');
    }
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    if (this.autoBlur) {
      this.renderer.setStyle(this.el.nativeElement, 'filter', 'blur(5px)');
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.renderer.setStyle(this.el.nativeElement, 'filter', 'blur(0px)');
  }
}
