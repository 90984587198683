import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor( private http: HttpClient,) { }

  url = environment.serverAPI + "/api/catalog/warehouse";

  url1 = environment.serverAPI4 + "/api/ig/warehouse/byLocation";

  getAllActiveWarehouse( ): Observable<Object> {
    return this.http.get(`${this.url}`);

  }


  getAllWarehouse(lat: any, long: any, distance: any): Observable<Object> {
    let params = new HttpParams()
    .set('lat', lat.toString())
    .set('lon', long.toString())
    .set('distance', distance.toString());
    return this.http.get(`${this.url1}`, { params });
  }
  
}
