import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { About, Aboutus, Calsoft, Cancellation, Easypayment, Faq, Jobs, Loyalty, Paymentmethods, PressRelease, Privacy, Shipping, Tercondition, Vat, Warranty } from '../models/about';
import { Subscriper } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class AboutService {



  public url = "assets/data/";

  url1 = environment.serverAPI3 + "/api/newslettersubscriber";



  subscriper(subscriper: Subscriper): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}`, subscriper);
  }

  constructor(private http: HttpClient) { }

  public getAbout(): Observable<About[]> {
    return this.http.get<About[]>(this.url + 'about.json');
  }

  public getCalsoft(): Observable<Calsoft[]> {
    return this.http.get<Calsoft[]>(this.url + 'calsoft.json');
  }

  public getPrivacy(): Observable<Privacy[]> {
    return this.http.get<Privacy[]>(this.url + 'privacy.json');
  }

  public getShipping(): Observable<Shipping[]> {
    return this.http.get<Shipping[]>(this.url + 'shipping.json');
  }

  public getFaq(): Observable<Faq[]> {
    return this.http.get<Faq[]>(this.url + 'faq.json');
  }

  public getVat(): Observable<Vat[]> {
    return this.http.get<Vat[]>(this.url + 'vat.json');
  }

  public getJobs(): Observable<Jobs[]> {
    return this.http.get<Jobs[]>(this.url + 'jobs.json');
  }

  public getLoyalty(): Observable<Loyalty[]> {
    return this.http.get<Loyalty[]>(this.url + 'loyalty.json');
  }

  public getEasypayment(): Observable<Easypayment[]> {
    return this.http.get<Easypayment[]>(this.url + 'easypayment.json');
  }

  public getWarranty(): Observable<Warranty[]> {
    return this.http.get<Warranty[]>(this.url + 'warranty.json');
  }

  public getTcondition(): Observable<Tercondition[]> {
    return this.http.get<Tercondition[]>(this.url + 'tconditions.json');
  }

  public getAboutus(): Observable<Aboutus[]> {
    return this.http.get<Aboutus[]>(this.url + 'aboutus.json');
  }

  public getPress(): Observable<PressRelease[]> {
    return this.http.get<PressRelease[]>(this.url + 'pressrelease.json');
  }

  public getPaymentMethod(): Observable<Paymentmethods[]> {
    return this.http.get<Paymentmethods[]>(this.url + 'paymentmethods.json');
  }

  public getCancellation(): Observable<Cancellation[]> {
    return this.http.get<Cancellation[]>(this.url + 'cancellation.json');
  }


  getPageByIdNew(title: any,Page:any,size:any): Observable<Object> {
    let url = environment.serverAPI4 + '/' + 'api/catalog/cms/page/init';
    return this.http.get(`${url}?title=${title}&page=${Page}&size=${size}`);
  }

  getPageByUUid(title: any,Page:any,size:any): Observable<Object> {
    let url = environment.serverAPI4 + '/' + 'api/catalog/cms/page/uuid';
    return this.http.get(`${url}?title=${title}&page=${Page}&size=${size}`);
  }


  getPageById(title: any): Observable<Object> {
    let url = environment.serverAPI4 + '/' + 'api/catalog/cms/page';
    return this.http.get(`${url}?title=${title}`);
  }

  

	url2 = environment.serverAPI4 + '/' + 'api/catalog/cms/page';

  
  public responseCacheAbout = new Map();

  public getPageShortAbout(title: any): Observable<any> {

		
		const aboutFromCache = this.responseCacheAbout.get(`${this.url2}?title=${title}`);
		if (aboutFromCache) {
			return of(aboutFromCache);
		}else{
			const response = this.http.get<any>(`${this.url2}?title=${title}`);
			response.subscribe(category => this.responseCacheAbout.set(`${this.url2}?title=${title}`, category));
			return response;
		}
		
	}

}
