import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';
import { FieldConfig } from '../field.interface';
import { Constant } from 'src/app/constants/constant';
import { ProductListiingService } from 'src/app/services/product-listiing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';
import { NewSubscriptionService } from 'src/app/services/new-subscription.service';

@Component({
  exportAs: "dynamicForm",
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  
})
export class DynamicFormComponent {
  @Input() fields: FieldConfig[] = [];
  @Input() layout: any = 2;
  @Input() gapBetweenImage: any = '5px';
  @Input() displayMobileImage: any = 2;
  @Input() mobileGapBetweenImage: any = 2;
  @Input() customField13: any = 0;
  @Input() customField5: any = 0;
@Input() privacydescripition : any = '' ;
@Input() broucherfile : any = '';
  @Input() customField4: any=0;
  @Input() customField32 : any=0;
  @Input() customField33 : any=0;

  @Input() newFormField: any = 0;

  @Input() SuccessMessage: any = 'Form Submitted SuccessFully';

  @Input() FailerMessage: any = 'Form Submitted Failed';

  @Input() redirectUrl: any = '/home';

  objectTobeDisplayed: any;

  minusValue: any = '2px';
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  formInit: boolean = false;

  formInit1: boolean = false;

  enableConditionalField?: any;
  @Input() patchValueList: any[] = [];
  previousFormField: any;
  indexNew: number = 0;
  valuesInputed: any = [];
  dontDisplayBackButton: boolean = false; imageList: any = [];

  previousFormFieldsList: any[] = [];

  text: string;
  initSecondForm: boolean = false;
  formInitSecond: boolean = false;
  EnablePayment: any =0;
  selectedCustomFieldValue: any;
  amount: any;
  calculatedResult: any;
  buttonType: any;
  calculaterEnable: boolean = false;
  calculatedValue: any;

  get value() {
    return this.form.value;
  }

  constructor(private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private productListiingService: ProductListiingService,
    private subscriptionService : SubscriptionPriceService,
    private newSubscriptionService: NewSubscriptionService,
  ) { }

  reInit: any = true;
  excludedInputTypes: Set<string> = new Set(['text']); 




enableFormPayment: boolean = false;

formValid: boolean = false;

contact: {};

  ngOnInit() {


    if (this.customField13 == 1) {


      this.initSecondForm = true;
    }
    

    this.form = this.createControl();

    this.subscribeToFormChanges();

    this.valuesInputed.push(this.fields[0].name)
    this.formInit = true;
    this.previousFormField = this.fields[0].name;
    this.objectTobeDisplayed = this.fields[0];
    this.formInit1 = true;
    this.indexNew = 0;

    this.EnablePayment = this.customField5;

    this.checkForSelectAndCustomField();

    this.subscriptionService.form$.subscribe(form => {

      if (form != null) {
        this.form = form;
        if (this.form.valid) {

          let object = {
            "name": this.form.value.name,
            "contact": this.form.value.phoneNumber,
            "email": this.form.value.email,
          }
          this.contact = object;
          this.formValid = true;
        }


      }

    });


    this.subscriptionService.amount$.subscribe(amount => {
      this.amount = amount;
    });

  }


  ngOnChanges() {
    this.checkForSelectAndCustomField();
  }
  
  checkForSelectAndCustomField(): void {
    this.enableFormPayment = this.fields.some(field => field.inputType === 'select' && field.customfield4 === '2' || field.customfield4 === '4');
  }



  onSubmit() {
    if (this.form.valid) {

        const formValues = this.form.value; 
        const calculationField = this.fields.find(
          field => field.inputType === 'button' && field.customfield4 === "3"
        );
    
        if (calculationField && calculationField.customField6) {
          const formula = calculationField.customField6;
          this.calculatedResult = this.evaluateFormula(formula, formValues);
    
          console.log('Calculated Result:', this.calculatedResult);

          this.calculatedValue = this.calculatedResult;

          this.calculaterEnable = true
    
          this.form.patchValue({ calculatedResult: this.calculatedResult });
          
          this.submit.emit({ ...formValues, calculatedResult: this.calculatedResult });
        } else {

          this.submit.emit(formValues);
        }
    
      


      this.submit.emit(this.form.value);
      
      this.form.reset();
    } else {
      this.validateAllFormFields(this.form);
    }
  }


  evaluateFormula(formula: string, values: any): number {
    const formulaWithValues = formula.replace(/(\w+)/g, (match) => {
      return values[match] !== undefined ? values[match] : match;
    });
    return eval(formulaWithValues);
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field['dynamicFormLabelValidator'] || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  subscribeToFormChanges() {
    this.form.valueChanges.subscribe(changes => {
      const changedField = Object.keys(changes).find(key => this.form.get(key)?.dirty);

      if (changedField) {
        const fieldConfig = this.fields.find(field => field.name === changedField);
        const fieldInputType = fieldConfig ? fieldConfig.inputType : null;

        if (!this.excludedInputTypes.has(fieldInputType)&&this.customField13==1 ) {
          this.subscriptionService.updateFormValue(this.form);
          this.updateConditionalFieldsVisibility();
        }else if(this.customField13==0){
          this.subscriptionService.updateFormValue(this.form);
          this.updateConditionalFieldsVisibility();
        }
      }
    });
  }
  updateConditionalFieldsVisibility() {
    this.fields.forEach(field => {
      if (field.enableConditionalField == 1) {
        const conditionResult = this.evaluateCondition(field.conditionText, this.form.value);
  
        if (conditionResult) {
          let index = this.valuesInputed.findIndex(x => x == field.name);
  
          if (index < 0) {
            this.objectTobeDisplayed = field;
            this.formInit1 = true;
            this.valuesInputed.push(this.objectTobeDisplayed.name);
            this.indexInital = this.indexInital + 1;
          }
  
          if (this.valuesInputed.length > 1) {
            this.dontDisplayBackButton = true;
          }
        }
  
        field.visible = conditionResult;
      }
    });
  }
  
  evaluateCondition(conditionText: string, formValues: any): boolean {
    if (!conditionText) return true; // If no condition specified, return true
    return this.evaluateExpression(conditionText, formValues);
  }
  
  evaluateExpression(expression: string, formValues: any): boolean {
    if (!expression) return true;
  
    expression = expression.replace(/\s+or\s+/g, ' || ').replace(/\s+and\s+/g, ' && ');
  
    const blocks = expression.split(/\s*\|\|\s*/);
  
    for (const block of blocks) {
      // Split block by AND (&&) operator
      const conditions = block.split(/\s*&&\s*/);
      let allConditionsTrue = true;
  
      for (const condition of conditions) {
        const conditionResult = this.evaluateSingleCondition(condition.trim(), formValues);
        if (!conditionResult) {
          allConditionsTrue = false;
          break;
        }
      }
  
      if (allConditionsTrue) {
        return true; // If all conditions in a block are true, the OR block is true
      }
    }
  
    return false; // If none of the OR blocks are true
  }
  
  evaluateSingleCondition(condition: string, formValues: any): boolean {
    let [variable, operator, value] = condition.split(/\s*(===|!==|==|!=|<|>|<=|>=)\s*/);
  
    if (variable.includes('.length')) {
      const varWithoutLength = variable.replace('.length', '').trim();
      const variableValue = (formValues[varWithoutLength] as string)?.length ?? 0;

      const lengthValue = parseInt(value.replace(/'/g, ""), 10);
  
      switch (operator) {
        case '===':
        case '==':
          return variableValue === lengthValue;
        case '!==':
        case '!=':
          return variableValue !== lengthValue;
        case '<':
          return variableValue < lengthValue;
        case '>':
          return variableValue > lengthValue;
        case '<=':
          return variableValue <= lengthValue;
        case '>=':
          return variableValue >= lengthValue;
        default:
          return false;
      }
    } else {
      const variableValue = formValues[variable.trim()];
      const cleanValue = value.replace(/'/g, '');
      const variableValueNumber = parseFloat(variableValue);
  
      switch (operator) {
        case '===':
          return variableValue === cleanValue;
        case '!==':
          return variableValue !== cleanValue;
        case '==':
          return variableValue == cleanValue || variableValueNumber == parseFloat(cleanValue);
        case '!=':
          return variableValue != cleanValue && variableValueNumber != parseFloat(cleanValue);
        case '<':
          return variableValueNumber < parseFloat(cleanValue);
        case '>':
          return variableValueNumber > parseFloat(cleanValue);
        case '<=':
          return variableValueNumber <= parseFloat(cleanValue);
        case '>=':
          return variableValueNumber >= parseFloat(cleanValue);
        default:
          return false;
      }
    }
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      let minLength: number | null = null;
      let maxLength: number | null = null;
  
      validations.forEach(valid => {
        if (valid.name === 'required') {
          validList.push(Validators.required);
        } else if (valid.name === 'pattern') {
          validList.push(Validators.pattern(valid.validator));
        } else if (valid.name === 'minLength') {
          if (valid.minLength) {
            minLength = parseInt(valid.minLength);
          }
        } else if (valid.name === 'maxLength') {
          if (valid.maxlength) {
            maxLength = parseInt(valid.maxlength);
          }
        }
      });
  

      if (minLength !== null) {
        validList.push(Validators.minLength(minLength));
      }
      if (maxLength !== null) {
        validList.push(Validators.maxLength(maxLength));
      }
  
      return Validators.compose(validList);
    }
    return null;
  }
  
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  calculateFlexValue(layout: number, gapBetweenImage: boolean): string {
    const baseFlex = layout === 2 ? '50%' :
      layout === 3 ? '33.33%' :
        layout === 4 ? '25%' : '100%';
    return gapBetweenImage ? `calc(${baseFlex} - ${gapBetweenImage})` : baseFlex;
  }

  indexInital: any = 0;

 
  onFieldValueChange(value: any) {

    this.previousFormField = value;
 
    
    this.previousFormFieldsList.push(value);

    this.subscribeToFormChangesText()


  }

  selectedValueChangEmit(value: string): void {
    this.selectedCustomFieldValue = value;

    console.log(this.selectedCustomFieldValue);
    
  }

  onFieldValueChange1(value: any) {

    if(value == true){
      this.formInitSecond = true;
    }


  }
  Gotonextform(){
    this.formInitSecond=true
  }


  onFieldValueChangeImage(value: any) {
    this.imageList = value;
    this.onFieldValueChange(value);

    this.formInitSecond = true;

  }

  SubmitForm(object) {

    if (object.operationType = "New") {
      this.productListiingService.submitProductListing(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(this.SuccessMessage, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.router.navigate([this.redirectUrl]);
            this.form.reset();
            this.imageList = [];
            
          }
          else {
            this.snackBar.open(this.FailerMessage, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }



  }

  getIndex() {

    this.indexInital = this.indexInital;



  }

  back() {

    let length = 0;


    let newLength = this.valuesInputed.length - 1;


    let indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }
  indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength-1]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }



    if (this.valuesInputed.length == 1) {
      this.dontDisplayBackButton = false;
      length = 0;
    } else {

      this.valuesInputed = this.valuesInputed.slice(0, -1);
      length = this.valuesInputed.length - 1;

    }


    if (length == 0) {
      this.dontDisplayBackButton = false;
    }

    let index = this.fields.findIndex(x => x['name'] == this.valuesInputed[length]);

    if (index > -1) {

      this.objectTobeDisplayed = this.fields[index];
    }


  }

  goToState(index: number) {
    if (index >= 0) {
      
      // for (let i = index + 1; i < this.fields.length; i++) {
      //   if (this.form.get(this.fields[i].name)) {
      //     this.form.get(this.fields[i].name).setValue('');
      //   }
      // }


      let newIndex= index - 1;

      let valuesAfterIndex = this.valuesInputed.slice(newIndex + 1);

      console.log(valuesAfterIndex);

      let i=0;
      valuesAfterIndex.forEach(element => {

        this.form.get(element).setValue('');

        let indexForm = this.valuesInputed.findIndex(x => x ==element);

        if(indexForm>-1&&i>0){
          this.valuesInputed.splice(indexForm ,1);
        }
        i++;
      });
      
     // this.valuesInputed=  this.valuesInputed.splice(newIndex + 1);

     // this.valuesInputed = this.fields.slice(0, index + 1).map(field => field.name);
  
      if (index == 0) {
        this.valuesInputed = [this.fields[0].name];
        this.dontDisplayBackButton = false;
      }
  
      let name=this.valuesInputed[this.valuesInputed.length-1];
      let indexNew = this.fields.findIndex(x => x.name ==name);
  
      this.objectTobeDisplayed = this.fields[indexNew];
    }
  }
  

  submitSecondForm(value) {

    console.log(value);
    
    const mergedObj = { ...this.form.value, ...value };

    mergedObj.productListingImages = this.imageList;

    mergedObj['value'] = JSON.stringify(mergedObj);
    this.SubmitForm(mergedObj)


  }

  
  subscribeToFormChangesText() {
  

      this.subscriptionService.updateFormValue(this.form);
      
      this.updateConditionalFieldsVisibility();
   
  }


  paymentResponceRazorPAy(i) {
    let payment = 'razorPay';
    let paymentId = i.paypalPaymentStatus;
    let orderTranscationId = i.salesPaymentTransaction[0].txn_id;
    let mode = "razor_pay";

    let object = {
      paymentId: paymentId,
      paymentTranscId: orderTranscationId,
      mode: "razor_pay",
      form: JSON.stringify(this.form.value),
      paymentInformation: JSON.stringify({
        paymentId: paymentId,
        paymentTranscId: orderTranscationId,
        mode: "razor_pay"
    })
  }

    if (object != null) {
      this.finalSumbit(object);
    }


  }

  amountReceived:any;

  finalSumbit(object) {

    if (object != null) {
      
      let formData = JSON.parse(object.form);

      let paymentInfo = JSON.parse(object.paymentInformation);

      this.amountReceived = localStorage.getItem('amount')

      let saveForm = {
        name: formData.name,
        contact: formData.phoneNumber,
        email: formData.email,
        rollNo: formData.rollNo,
        sections: formData.sections,
        accountNo: formData.accountNo,
        ifscCode: formData.bankIfscCode,
        customField1: formData.customField1,
        customField2: formData.customField2,
        transactionId: paymentInfo.paymentTranscId,
        customField3: this.amountReceived,
        customField4: formData.customField4,
        customField5: formData.customField5,
        customField6: formData.customField6,
        customField7: formData.customField7,
        customField8: formData.customField8,
        customField9: formData.customField9,
        customField10: formData.customField10,
        value: JSON.stringify(object),
      };

      if (saveForm != null) {
        this.newSubscriptionService.Customformsubmission(saveForm).subscribe(
          (response) => {

            if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
              this.snackBar.open(this.SuccessMessage, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
              this.form.reset();
              this.router.navigate([this.redirectUrl]);
              
            }
            else {
              this.snackBar.open(this.FailerMessage, '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            }
          },
          (err) => { "object is null" });
      }
      else {
        (err) => {
          console.error('Error occurred:', err);
          this.snackBar.open('Failed to save data', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }

      }
    }
  }

}