<div class="terms-container">
    <input type="checkbox" id="acceptCheckbox" [disabled]="!hasReadPolicy" [(ngModel)]="isChecked" />
    <label for="acceptCheckbox">
      I’ve read and agree to the 
      <a (click)="openPrivacyPolicy($event)">Privacy Policy and Terms</a>.
    </label>
  </div>
  
  <!-- Modal Popup -->
  <div class="modal" *ngIf="showPopup">
    <div class="modal-content">
      <span class="close-button" (click)="closePrivacyPolicy()">&times;</span>
      <h2>Privacy Policy and Terms</h2>
      <div class="privacy-content" [innerHTML]="privacywords">
        <!-- Privacy policy content will go here -->
      </div>
      <div class="button-container">
        <button (click)="acceptPolicy()">Accept</button>
      </div>
    </div>
  </div>
  